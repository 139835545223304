import { BasicQueryStringUtils, LocationLike } from '@openid/appauth'
import cookie from 'cookie'
import { NextPageContext } from 'next'

export class NoHashQueryStringUtils extends BasicQueryStringUtils {
  parse(input: LocationLike, useHash?: boolean) {
    useHash = false /* never use hash */
    return super.parse(input, useHash)
  }
}

export const getJWTPayload = (token: string | undefined): any | null => {
  // use this to get your token quickly!
  // console.log(token)
  if (!token) return null
  /* eslint-disable-next-line @typescript-eslint/no-unused-vars */
  const [_, userInfo] = token.split('.')
  const payload = Buffer.from(userInfo, 'base64')
  return JSON.parse(payload.toString())
}

export const parseUser = (token: string | undefined | null): LumosUser | null => {
  if (!token) return null
  const { sub, exp, flow, username } = getJWTPayload(token)
  const userId = parseInt(sub.split('|')[1], 10)

  return {
    id: userId,
    username,
    authExpiration: new Date(exp * 1000).valueOf(),
    flow,
  }
}

export const readStream = async (stream: ReadableStream<Uint8Array> | null): Promise<Uint8Array[]> => {
  if (!stream) return [] as Uint8Array[]
  const reader = stream.getReader()
  const chunks = [] as Uint8Array[]

  let ended
  while (!ended) {
    const { done, value } = await reader.read()
    ended = done
    if (done) {
      return chunks
    }
    chunks.push(value as Uint8Array)
  }
  return [] as Uint8Array[]
}

export function parseCookies(req: NextPageContext['req']) {
  return cookie.parse(req ? req.headers.cookie || '' : document.cookie)
}
